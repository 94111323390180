
export const PUBLIC_URL = "";
// export const PUBLIC_URL = "/ticket-site";

export const staticFiles = {
  icons: {
    cart: `${PUBLIC_URL}/assets/icons/cart.svg`,
    lock: `${PUBLIC_URL}/assets/icons/lock.svg`,
    profile: `${PUBLIC_URL}/assets/icons/profile.svg`,
    sign_up: `${PUBLIC_URL}/assets/icons/sign_up.svg`,
    facebook: `${PUBLIC_URL}/assets/icons/facebook.svg`,
    instagram: `${PUBLIC_URL}/assets/icons/instagram.svg`,
    blog: `${PUBLIC_URL}/assets/icons/blog.svg`,
    communicate: `${PUBLIC_URL}/assets/icons/실시간 상담.svg`,
    google: `${PUBLIC_URL}/assets/icons/google.svg`,
    naver: `${PUBLIC_URL}/assets/icons/naver.svg`,
    messages_bubble: `${PUBLIC_URL}/assets/icons/messages_bubble.svg`,
    shopping_bag: `${PUBLIC_URL}/assets/icons/shopping_bag.svg`,
    dollar_circle: `${PUBLIC_URL}/assets/icons/dollar_circle.svg`,
    honest: `${PUBLIC_URL}/assets/icons/정직.svg`,
    call: `${PUBLIC_URL}/assets/icons/call.svg`,
    thumbs_up: `${PUBLIC_URL}/assets/icons/thumbs_up.svg`,
    coffee: `${PUBLIC_URL}/assets/icons/coffee.svg`,
    translation: `${PUBLIC_URL}/assets/icons/translation.svg`,
    location: `${PUBLIC_URL}/assets/icons/location.svg`,
    refundlayout: `${PUBLIC_URL}/assets/icons/환불.svg`,
    tag_white: `${PUBLIC_URL}/assets/icons/tag_white.svg`,
    decrement: `${PUBLIC_URL}/assets/icons/decrement.png`,
    increment: `${PUBLIC_URL}/assets/icons/increment.png`,
    green_check: `${PUBLIC_URL}/assets/icons/green_check.svg`,
    black_check: `${PUBLIC_URL}/assets/icons/black_check.svg`,
    gold_medal: `${PUBLIC_URL}/assets/icons/프리미엄S_15x15.png`,
    black_medal: `${PUBLIC_URL}/assets/icons/프리미엄_15x15.png`,
    heart: `${PUBLIC_URL}/assets/icons/location-icon.svg`,
    calendar: `${PUBLIC_URL}/assets/icons/calendar.svg`,
    greenCalendar: `${PUBLIC_URL}/assets/icons/green_calendar.svg`,
    activity_1: `${PUBLIC_URL}/assets/icons/activity_1.svg`,
    activity_2: `${PUBLIC_URL}/assets/icons/activity_2.svg`,
    activity_3: `${PUBLIC_URL}/assets/icons/activity_3.svg`,
    card_calendar: `${PUBLIC_URL}/assets/icons/card_calendar.svg`,
    card_baby: `${PUBLIC_URL}/assets/icons/ticket_child.svg`,
    card_adult: `${PUBLIC_URL}/assets/icons/ticket_adult.svg`,
    down_arrow: `${PUBLIC_URL}/assets/icons/down_arrow.svg`,
    left_arrow: `${PUBLIC_URL}/assets/icons/left_arrow.svg`,
    right_arrow: `${PUBLIC_URL}/assets/icons/right_arrow.svg`,
    paper: `${PUBLIC_URL}/assets/icons/paper.svg`,
    shop: `${PUBLIC_URL}/assets/icons/shop.svg`,
    clock: `${PUBLIC_URL}/assets/icons/clock.svg`,
    info: `${PUBLIC_URL}/assets/icons/info.svg`,
    refund: `${PUBLIC_URL}/assets/icons/refund.svg`,
    faq: `${PUBLIC_URL}/assets/icons/faq.svg`,
    disposition: `${PUBLIC_URL}/assets/icons/disposition.svg`,
    sign_out: `${PUBLIC_URL}/assets/icons/sign_out.svg`,
    edit: `${PUBLIC_URL}/assets/icons/edit.svg`,
    menu: `${PUBLIC_URL}/assets/icons/menu.svg`,
    open_eye: `${PUBLIC_URL}/assets/icons/open_eye.svg`,
    closed_eye: `${PUBLIC_URL}/assets/icons/closed_eye.svg`,
    remove: `${PUBLIC_URL}/assets/icons/remove.svg`,
    help_circle: `${PUBLIC_URL}/assets/icons/help-circle.svg`,
    schedule_optionconfirm: `${PUBLIC_URL}/assets/icons/schedule_optionconfirm.svg`,
    world: `${PUBLIC_URL}/assets/icons/world.svg`,
    schedule: `${PUBLIC_URL}/assets/icons/schedule.png`,
    volume_high: `${PUBLIC_URL}/assets/icons/volume-high.svg`,
    sim_card: `${PUBLIC_URL}/assets/icons/sim_card.svg`,
  },
  images: {
    popup_image: `${PUBLIC_URL}/images/popup-image.png`,
    logo: `${PUBLIC_URL}/assets/icons/logo.png`,
    cover_bg: `${PUBLIC_URL}/images/TopRock_banner.png`,
    big_apple_pass_price: `${PUBLIC_URL}/images/빅애플패스 가격표.png`,
    ny_big_apple_pass_price_mobile: `${PUBLIC_URL}/images/빅애플패스 가격표_mobile.png`,
    mobile_bannerFont: `${PUBLIC_URL}/images/TopRock_banner_f_mobile.png`,
    pc_bannerFont: `${PUBLIC_URL}/images/TopRock_banner_f_pc.png`,
    big_apple_pass_cover_bg: `${PUBLIC_URL}/images/Bigapplepass_banner.svg`,
    big_apple_pass_mobile_bannerFont: `${PUBLIC_URL}/images/Bigapplepass_banner_f_mobile.svg`,
    big_apple_pass_pc_bannerFont: `${PUBLIC_URL}/images/Bigapplepass_banner_f_pc.svg`,
    sim_cover_bg: `${PUBLIC_URL}/images/SIM_banner.svg`,
    sim_mobile_bannerFont: `${PUBLIC_URL}/images/SIM_banner_f_mobile.svg`,
    sim_pc_bannerFont: `${PUBLIC_URL}/images/SIM_banner_f_pc.svg`,
    musical_cover_bg: `${PUBLIC_URL}/images/Musical_banner.svg`,
    musical_mobile_bannerFont: `${PUBLIC_URL}/images/Musical_banner_f_mobile.svg`,
    musical_pc_bannerFont: `${PUBLIC_URL}/images/Musical_banner_f_pc.svg`,
    tamice_page_cover_bg: `${PUBLIC_URL}/images/tamice_page_banner.svg`,
    tamice_page_mobile_bannerFont: `${PUBLIC_URL}/images/tamice_page_banner_f_mobile.svg`,
    tamice_page_pc_bannerFont: `${PUBLIC_URL}/images/tamice_page_banner_f_pc.svg`,
    music_cover_bg: `${PUBLIC_URL}/images/TheMet_banner.svg`,
    music_mobile_bannerFont: `${PUBLIC_URL}/images/TheMet_banner_f_mobile.svg`,
    music_pc_bannerFont: `${PUBLIC_URL}/images/TheMet_banner_f_pc.svg`,
    cancel_banner: `${PUBLIC_URL}/images/cancel_banner_0122.svg`,
    cancel_mobile_bannerFont: `${PUBLIC_URL}/images/cancel_banner_f_mobile_0122.svg`,
    cancel_pc_bannerFont: `${PUBLIC_URL}/images/cancel_banner_f_pc_0122.svg`,
    toprock_cover_bg: `${PUBLIC_URL}/images/TopRock_banner (1).svg`,
    toprock_mobile_bannerFont: `${PUBLIC_URL}/images/unifyq_topoftherock_banner_f_mobile_0122 1.svg`,
    toprock_pc_bannerFont: `${PUBLIC_URL}/images/unfiyq_topoftherock_banner_f_pc_0122 1.svg`,
    airport_cover_bg: `${PUBLIC_URL}/images/Airport_banner.svg`,
    airport_mobile_bannerFont: `${PUBLIC_URL}/images/unifyq_Airport_banner_f_mobile_0122 (1).svg`,
    airport_pc_bannerFont: `${PUBLIC_URL}/images/unifyq_Airport_banner_f_pc _0122 1.svg`,
    cruise_cover_bg: `${PUBLIC_URL}/images/Cruise_banner.svg`,
    cruise_mobile_bannerFont: `${PUBLIC_URL}/images/Cruise_banner_f_mobile.svg`,
    cruise_pc_bannerFont: `${PUBLIC_URL}/images/Cruise_banner_f_pc.svg`,
    bus_cover_bg: `${PUBLIC_URL}/images/unifyq_BUSTOUR_banner.svg`,
    bus_mobile_bannerFont: `${PUBLIC_URL}/images/unifyq_Bustour_banner_f_pc_0122 1.svg`,
    bus_pc_bannerFont: `${PUBLIC_URL}/images/unifyq_Bustour_banner_f_mobile_0122 1.svg`,
    activity_cover_bg: `${PUBLIC_URL}/images/unifyq_Activity_banner.svg`,
    activity_mobile_bannerFont: `${PUBLIC_URL}/images/unifyq_Activity_banner_f_pc_0122 1.svg`,
    activity_pc_bannerFont: `${PUBLIC_URL}/images/unifyq_Activity_banner_f_mobile_0122 1.svg`,
    contact_location: `${PUBLIC_URL}/images/tamicemap.svg`,
    landing_page: `${PUBLIC_URL}/images/landing.jpg`,
    ny_main_page: `${PUBLIC_URL}/images/main page-image_NY.jpg`,
    sf_main_page: `${PUBLIC_URL}/images/main page-image_SF.jpg`,
    cover_show: `${PUBLIC_URL}/images/TopRock_banner.png`,
    product_detail_traveler: `${PUBLIC_URL}/images/product_detail_traveler.png`,
    delete_icon: `${PUBLIC_URL}/images/Delete.png`,
    restart_icon: `${PUBLIC_URL}/images/Restart.png`,
    여행정보_destination1: `${PUBLIC_URL}/images/blog1.jpg`,
    여행정보_destination2: `${PUBLIC_URL}/images/blog2.jpg`,
    여행정보_destination3: `${PUBLIC_URL}/images/blog3.jpg`,
    여행정보_destination4: `${PUBLIC_URL}/images/blog4.jpg`,
    여행정보_image1: `${PUBLIC_URL}/images/EVENT1.svg`,
    여행정보_image2: `${PUBLIC_URL}/images/EVENT2.svg`,
    sf_event1: `${PUBLIC_URL}/images/SF1.jpg`,
    sf_event2: `${PUBLIC_URL}/images/SF2.jpg`,
    sf_event3: `${PUBLIC_URL}/images/SF3.jpeg`,
    sf_event4: `${PUBLIC_URL}/images/SF4.jpg`,
    sf_event_cover_bg: `${PUBLIC_URL}/images/SFEvent_banner_0122.svg`,
    sf_event_mobile_bannerFont: `${PUBLIC_URL}/images/SFEvent_banner_f_mobile_0122.svg`,
    sf_event_pc_bannerFont: `${PUBLIC_URL}/images/SFEvent_banner_f_pc_0122.svg`,
    sf_bgf_apple_pass: `${PUBLIC_URL}/images/SF Big Apple Pass.svg`,
    sf_bike: `${PUBLIC_URL}/images/Bike.svg`,
    sf_bus: `${PUBLIC_URL}/images/BigBus.svg`,
    sf_cruise: `${PUBLIC_URL}/images/Cruise.svg`,
    travel_term_bg_cover: `${PUBLIC_URL}/images/22_travel.svg`,
    use_term_bg_cover: `${PUBLIC_URL}/images/54_use.svg`,
    privacy_bg_cover: `${PUBLIC_URL}/images/55_privacy.svg`,
    sf_museum: `${PUBLIC_URL}/images/Museum.svg`,
    refund_cover_bg: `${PUBLIC_URL}/images/취소 및 환불규정_banner_0122.svg`,
    refund_mobile_bannerFont: `${PUBLIC_URL}/images/취소 및 환불규정_banner_f_mobile_01221.svg`,
    refund_pc_bannerFont: `${PUBLIC_URL}/images/취소 및 환불규정_banner_f_pc_01221.svg`,
    ny_event_cover_bg: `${PUBLIC_URL}/images/Event_banner_NY.svg`,
    ny_event_mobile_bannerFont: `${PUBLIC_URL}/images/Event_banner_f_mobile_NY.svg`,
    ny_event_pc_bannerFont: `${PUBLIC_URL}/images/Event_banner_f_pc_NY.svg`,
    sf_museum_cover_bg: `${PUBLIC_URL}/images/Aquarium_banner.svg`,
    sf_museum_mobile_bannerFont: `${PUBLIC_URL}/images/SF_Aquarium_banner_f_mobile.svg`,
    sf_museum_pc_bannerFont: `${PUBLIC_URL}/images/SF_Aquarium_banner_f_pc.svg`,
    my_page_cover_bg: `${PUBLIC_URL}/images/Mypage_banner.svg`,
    my_page_mobile_bannerFont: `${PUBLIC_URL}/images/unifyq_Mypage_banner_f_mobile.svg`,
    my_page_pc_bannerFont: `${PUBLIC_URL}/images/unifyq_Mypage_banner_f_pc.svg`,
    about_service: `${PUBLIC_URL}/images/about_ONE-STOP 서비스.svg`,
    about_free: `${PUBLIC_URL}/images/about_무료 짐 보관 서비스.svg`,
    about_safe: `${PUBLIC_URL}/images/about_안심 직거래.svg`,
    about_tamice: `${PUBLIC_URL}/images/about_타미스 라운지.svg`,
    sf_bigapple_pass: `${PUBLIC_URL}/images/SFBigapplepass_banner.svg`,
    sf_bigapple_pass_banner_mobile: `${PUBLIC_URL}/images/unifyq_SFBigapplepass_banner_f_mobile.svg`,
    sf_bigapple_pass_banner_pc: `${PUBLIC_URL}/images/unifyq_SFBigapplepass_banner_f_pc.svg`,
    sf_city_pass: `${PUBLIC_URL}/images/SFCitypass_banner.svg`,
    sf_city_pass_banner_mobile: `${PUBLIC_URL}/images/SFCitypass_banner_f_mobile.svg`,
    sf_city_pass_banner_pc: `${PUBLIC_URL}/images/SFCitypass_banner_f_pc.svg`,
    ny_city_pass: `${PUBLIC_URL}/images/NY_citypass_banner_0125.svg`,
    ny_city_pass_banner_mobile: `${PUBLIC_URL}/images/NY_Citypass_banner_f_mobile_0122 1 (1).svg`,
    ny_city_pass_banner_pc: `${PUBLIC_URL}/images/NY_Citypass_banner_f_pc_0122 1.svg`,
    sf_explore_pass: `${PUBLIC_URL}/images/SFExplorer__banner_0125.svg`,
    sf_explore_banner_mobile: `${PUBLIC_URL}/images/SFexplorer_banner_f_mobile_0122.svg`,
    sf_explore_banner_pc: `${PUBLIC_URL}/images/SFexplorer_banner_f_PC_0122.svg`,
    login_banner: `${PUBLIC_URL}/images/Login_banner.svg`,
    login_banner_mobile: `${PUBLIC_URL}/images/unifyq_Login_banner_f_mobile_0122 1.svg`,
    login_banner_pc: `${PUBLIC_URL}/images/unifyq_Login_banner_f_pc_0122 1.svg`,
    signup_banner: `${PUBLIC_URL}/images/Signup_banner_0131_2.svg`,
    signup_banner_mobile: `${PUBLIC_URL}/images/Signin_banner_f_mobile_0122.svg`,
    signup_banner_pc: `${PUBLIC_URL}/images/Signin_banner_f_pc_0122.svg`,
    moma_banner: `${PUBLIC_URL}/images/unifyq_MoMA_banner.svg`,
    moma_banner_mobile: `${PUBLIC_URL}/images/unifyq_MoMA_banner_f_mobile_0122 1.svg`,
    moma_banner_pc: `${PUBLIC_URL}/images/unifyq_MoMA_banner_f_pc_0122 1.svg`,
    cruise_banner: `${PUBLIC_URL}/images/Unifyq_Cruise_banner.svg`,
    cruise_banner_mobile: `${PUBLIC_URL}/images/Unifyq_Cruise_banner_f_mobile_0122 1.svg`,
    cruise_banner_pc: `${PUBLIC_URL}/images/Unifyq_Cruise_banner_f_pc_0122 1.svg`,
    observations_banner: `${PUBLIC_URL}/images/NY_Landing Page_banner_0228.svg`,
    observations_mobile: `${PUBLIC_URL}/images/NY_Obersvations_f_mobile_0122 1.svg`,
    observations_pc: `${PUBLIC_URL}/images/NY_Obserbations_f_pc_0122 1.svg`,
    doson_banner: `${PUBLIC_URL}/images/doson-tour_banner.svg`,
    doson_mobile: `${PUBLIC_URL}/images/doson-tour_f_mobile_0122 1.svg`,
    doson_pc: `${PUBLIC_URL}/images/doson-tour_f_pc_0122 1.svg`,
    neighbour_banner: `${PUBLIC_URL}/images/neighbour-tour_banner.svg`,
    neighbour_mobile: `${PUBLIC_URL}/images/neighbour-tour_banner_f_mobile_0122 1.svg`,
    neighbour_pc: `${PUBLIC_URL}/images/neighbour-tour_banner_f_pc_0122 1.svg`,
    daytour_banner: `${PUBLIC_URL}/images/DayTour_banner.svg`,
    daytour_mobile: `${PUBLIC_URL}/images/DayTour_banner_f_mobile_0122 1.svg`,
    daytour_pc: `${PUBLIC_URL}/images/DayTour_banner_f_pc_0122.svg`,
    untour_banner: `${PUBLIC_URL}/images/UnTour_banner_0126.svg`,
    untour_mobile: `${PUBLIC_URL}/images/UnTour_banner_f_mobile_0122 1.svg`,
    untour_pc: `${PUBLIC_URL}/images/UnTour_banner_f_pc_0122 1.svg`,
    NY_nighttour_banner: `${PUBLIC_URL}/images/NightTour_banner.svg`,
    NY_nighttour_mobile: `${PUBLIC_URL}/images/NightTour_banner_f_mobile_0122 1.svg`,
    NY_nighttour_pc: `${PUBLIC_URL}/images/NightTour_banner_f_pc_0122 1.svg`,
    NY_explore_pass_banner: `${PUBLIC_URL}/images/NY_Explorer__banner_0125.svg`,
    NY_explore_pass_mobile: `${PUBLIC_URL}/images/NY_Explorer_banner_f_mobile_0122 1.svg`,
    NY_explore_pass_pc: `${PUBLIC_URL}/images/NY_Explorer_banner_f_pc_0122 1.svg`,
    Cart_banner: `${PUBLIC_URL}/images/Cart_banner.svg`,
    Cart_mobile: `${PUBLIC_URL}/images/Cart_banner_f_mobile_0122 1.svg`,
    Cart_pc: `${PUBLIC_URL}/images/Cart_banner_f_pc_0122 1.svg`,
    SF_Activity_banner: `${PUBLIC_URL}/images/Gocar_banner_0122.svg`,
    SF_Activity_mobile: `${PUBLIC_URL}/images/Gocar_banner_f_mobile_0122 1.svg`,
    SF_Activity_pc: `${PUBLIC_URL}/images/Gocar_banner_f_pc_0122 1.svg`,
    Bigbus_banner: `${PUBLIC_URL}/images/Bigbus_banner.svg`,
    Bigbus_mobile: `${PUBLIC_URL}/images/Bigbus_banner_f_mobile_0122 1.svg`,
    Bigbus_pc: `${PUBLIC_URL}/images/Bigbus_banner_f_pc_0122 1.svg`,
    SF_Price_Chart: `${PUBLIC_URL}/images/SF_빅애플패스 가격표.png`,
    sim_image: `${PUBLIC_URL}/images/SimCard.svg`,
    sign_up_pc: `${PUBLIC_URL}/images/sign_up_banner_f_pc_0202 1.svg`,
    sign_up_mobile: `${PUBLIC_URL}/images/sign_up_banner_f_mobile_0202 1.svg`,
    main_page_slide: `${PUBLIC_URL}/images/빅애플패스 구매 이용 방법 이미지.jpg`,
    must_see_in_ny_bigapplepass: `${PUBLIC_URL}/images/Must See in NY_Bigapplepass.svg`,
    must_see_in_ny_cruisess: `${PUBLIC_URL}/images/Must See in NY_Cruises.svg`,
    must_see_in_ny_museum: `${PUBLIC_URL}/images/Must See in NY_Museum.svg`,
    must_see_in_ny_musical: `${PUBLIC_URL}/images/Must See in NY_Musical.svg`,
    must_see_in_ny_observatory: `${PUBLIC_URL}/images/Must See in NY_Observatory.svg`,
    must_see_in_ny_tour: `${PUBLIC_URL}/images/Must See in NY_Tour.svg`,
    must_see_in_sf_bigapplepass: `${PUBLIC_URL}/images/Must See in_SF_Bigapplepass.svg`,
    must_see_in_sf_activity: `${PUBLIC_URL}/images/Must See in_SF_Activity.svg`,
    must_see_in_sf_bustour: `${PUBLIC_URL}/images/Must See in_SF_BusTour.svg`,
    must_see_in_sf_cruises: `${PUBLIC_URL}/images/Must See in_SF_Cruises.svg`,
    must_see_in_sf_esim: `${PUBLIC_URL}/images/Must See in_SF_eSIM.svg`,
    must_see_in_sf_museum: `${PUBLIC_URL}/images/Must See in_SF_Museum.svg`,
    must_see_in_sf_tour_banner: `${PUBLIC_URL}/images/SF_Landing Page_banner_0228.svg`,
    must_see_in_sf_tour_banner_pc_text: `${PUBLIC_URL}/images/SF_Landing Page_banner_f_web_0312.svg`,
    must_see_in_sf_tour_banner_mobile_text: `${PUBLIC_URL}/images/SF_Landing Page_banner_f_mobile_0312.svg`,
    must_see_in_ny_tour_pc_banner: `${PUBLIC_URL}/images/NY_Landing Page_banner_f_web_0312.svg`,
    must_see_in_ny_tour_mobile_banner: `${PUBLIC_URL}/images/NY_Landing Page_banner_f_mobile_0312.svg`,
    bos_museum_banner: `${PUBLIC_URL}/images/Bos_Museum_banner_0227.svg`,
    bos_museum_pc: `${PUBLIC_URL}/images/Bos_Museum_banner_f_mobile_0227.svg`,
    bos_museum_mobile: `${PUBLIC_URL}/images/Bos_Museum_banner_f_web_0227.svg`,
    bos_bus_banner: `${PUBLIC_URL}/images/Bos_BusTour_banner_0227.svg`,
    bos_bus_pc: `${PUBLIC_URL}/images/Bos_BusTour_banner_f_web_0227.svg`,
    bos_bus_mobile: `${PUBLIC_URL}/images/Bos_BusTour_banner_f_mobile_0227.svg`,
    bos_apple_pass_price: `${PUBLIC_URL}/images/보스턴 가격표_0327.png`,
    bos_apple_pass_banner: `${PUBLIC_URL}/images/Bos_Bigapplepass_banner_0228.svg`,
    bos_apple_pass_pc: `${PUBLIC_URL}/images/Bos_Bigapplepass_banner_f_web_0228.svg`,
    bos_apple_pass_mobile: `${PUBLIC_URL}/images/Bos_Bigapplepass_banner_f_mobile_0228.svg`,
    bos_cruises_banner: `${PUBLIC_URL}/images/Bos_Cruises_banner_0227.svg`,
    bos_cruises_pc: `${PUBLIC_URL}/images/Bos_Cruises_banner_f_web_0227.svg`,
    bos_cruises_mobile: `${PUBLIC_URL}/images/Bos_Cruises_banner_f_mobile_0227.svg`,
    bos_ivy_league_banner: `${PUBLIC_URL}/images/Bos_IvyTour_banner_0227.svg`,
    bos_ivy_league_pc: `${PUBLIC_URL}/images/Bos_IvyTour_banner_f_web_0227.svg`,
    bos_ivy_league_mobile: `${PUBLIC_URL}/images/Bos_IvyTour_banner_f_mobile_0227.svg`,
    bos_landing_banner: `${PUBLIC_URL}/images/Bos_Landing Page_banner_0228.svg`,
    bos_landing_pc: `${PUBLIC_URL}/images/Bos_Landing Page_banner_f_web_0312.svg`,
    bos_landing_mobile: `${PUBLIC_URL}/images/Bos_Landing Page_banner_f_mobile_0312.svg`,
    nf_landing_banner: `${PUBLIC_URL}/images/NF_Landing Page_banner_0228.svg`,
    nf_landing_pc: `${PUBLIC_URL}/images/NF_Landing Page_banner_f_web_0312.svg`,
    nf_landing_mobile: `${PUBLIC_URL}/images/NF_Landing Page_banner_f_mobile_0312.svg`,
    must_see_in_nf_bigapplepass: `${PUBLIC_URL}/images/Must See in Niagara Falls_BIGAPPLEPASS.svg`,
    must_see_in_nf_activity: `${PUBLIC_URL}/images/Must See in Niagara Falls_Activity.svg`,
    must_see_in_nf_cruise: `${PUBLIC_URL}/images/Must See in Niagara Falls_Cruises.svg`,
    must_see_in_nf_attraction: `${PUBLIC_URL}/images/Must See in Niagara Falls_Tot Attraction.svg`,
    must_see_in_nf_tour: `${PUBLIC_URL}/images/Must See in Niagara Falls_Tour.svg`,
    must_see_in_nf_esim: `${PUBLIC_URL}/images/Must See in Niagara Falls_eSIM.svg`,
    nf_big_apple_pass_banner: `${PUBLIC_URL}/images/NF_Bigapplepass_banner_0228.svg`,
    nf_big_apple_pass_pc: `${PUBLIC_URL}/images/NF_Bigapplepass_banner_f_web_0228.svg`,
    nf_big_apple_pass_mobile: `${PUBLIC_URL}/images/NF_Bigapplepass_banner_f_mobile_0228.svg`,
    nf_big_apple_pass_price: `${PUBLIC_URL}/images/나이아가라 가격표_0327.png`,
    nf_cruise_banner: `${PUBLIC_URL}/images/NF_Cruise_banner_0228.svg`,
    nf_cruise_mobile: `${PUBLIC_URL}/images/NF_Cruise_banner_f_mobile_0228.svg`,
    nf_cruise_pc: `${PUBLIC_URL}/images/NF_Cruise_banner_f_web_0228.svg`,
    nf_entry_banner: `${PUBLIC_URL}/images/NF_Tour attractions_banner_0228.svg`,
    nf_entry_mobile: `${PUBLIC_URL}/images/NF_Tour attractions_banner_f_mobile_0324.svg`,
    nf_entry_pc: `${PUBLIC_URL}/images/NF_Tour attractions_banner_f_web_0228.svg`,
    nf_activity_banner: `${PUBLIC_URL}/images/NF_Activity_banner_0228.svg`,
    nf_activity_mobile: `${PUBLIC_URL}/images/NF_Activity_banner_f_mobile_0228.svg`,
    nf_activity_pc: `${PUBLIC_URL}/images/NF_Activity_banner_f_web_0228.svg`,
    nf_tour_banner: `${PUBLIC_URL}/images/NF_Free travel_banner_0228.svg`,
    nf_tour_mobile: `${PUBLIC_URL}/images/NF_Free travel_banner_f_mobile_0228.svg`,
    nf_tour_pc: `${PUBLIC_URL}/images/NF_Free travel_banner_f_web_0228.svg`,
    nf_sim_banner: `${PUBLIC_URL}/images/NF_eSIM_banner_0228.svg`,
    nf_sim_mobile: `${PUBLIC_URL}/images/NF_SIM_banner_f_mobile_0228.svg`,
    nf_sim_pc: `${PUBLIC_URL}/images/NF_eSIM_banner_f_web_0228.svg`,
    must_see_in_ls_bigapplepass: `${PUBLIC_URL}/images/Must See in Niagara Falls_BIGAPPLEPASS.svg`,
    must_see_in_ls_activity: `${PUBLIC_URL}/images/Must See in Niagara Falls_Activity.svg`,
    must_see_in_ls_cruise: `${PUBLIC_URL}/images/Must See in Niagara Falls_Cruises.svg`,
    must_see_in_ls_attraction: `${PUBLIC_URL}/images/Must See in Niagara Falls_Tot Attraction.svg`,
    must_see_in_ls_tour: `${PUBLIC_URL}/images/Must See in Niagara Falls_Tour.svg`,
    must_see_in_ls_esim: `${PUBLIC_URL}/images/Must See in Niagara Falls_eSIM.svg`,
    ls_landing_banner: `${PUBLIC_URL}/images/V-C_Landing Page_banner_0229.svg`,
    ls_landing_mobile: `${PUBLIC_URL}/images/V-C_Landing Page_banner_f_mobile_0312.svg`,
    ls_landing_pc: `${PUBLIC_URL}/images/V-C_Landing Page_banner_f_web_0312.svg`,
    ls_bigapple_pass_banner: `${PUBLIC_URL}/images/LV_Bigapplepass_banner_0229.svg`,
    ls_bigapple_pass_mobile: `${PUBLIC_URL}/images/LV_Bigapplepass_banner_f_mobile_0229.svg`,
    ls_bigapple_pass_pc: `${PUBLIC_URL}/images/LV_Bigapplepass_banner_f_web_0229.svg`,
    ls_bigapple_pass_price: `${PUBLIC_URL}/images/베가스 가격표_0327.png`,
    ls_campingcar_banner: `${PUBLIC_URL}/images/Canyon_Camping_banner_0229.svg`,
    ls_campingcar_mobile: `${PUBLIC_URL}/images/Canyon_Camping_banner_f_mobile_0229.svg`,
    ls_campingcar_pc: `${PUBLIC_URL}/images/Canyon_Camping_banner_f_web_0229.svg`,
    ls_canyon_banner: `${PUBLIC_URL}/images/Canyon_Tour_banner_0229.svg`,
    ls_canyon_mobile: `${PUBLIC_URL}/images/Canyon_Tour_banner_f_mobile_0324.svg`,
    ls_canyon_pc: `${PUBLIC_URL}/images/Canyon_Tour_banner_f_web_0403.svg`,
    ls_tour_banner: `${PUBLIC_URL}/images/LV_TourAttractions_banner_0229.svg`,
    ls_tour_mobile: `${PUBLIC_URL}/images/LV_TourAttractions_banner_f_mobile_0403.svg`,
    ls_tour_pc: `${PUBLIC_URL}/images/LV_TourAttractions_banner_f_web_0403.svg`,
    ls_canyon_package_banner: `${PUBLIC_URL}/images/Canyon_Tour_banner_0229.svg`,
    ls_canyon__package_mobile: `${PUBLIC_URL}/images/Canyon_Tour_banner_f_mobile_0403.svg`,
    ls_canyon_package_pc: `${PUBLIC_URL}/images/Canyon_Tour_banner_f_web_0403.svg`,
    landing_page_banner: `${PUBLIC_URL}/images/landing page_banner_0312.svg`,
    landing_page_banner_text_pc: `${PUBLIC_URL}/images/landing page_web_banner_f_pc_0312.svg`,
    landing_page_banner_text_mobile: `${PUBLIC_URL}/images/landing page_web_banner_f_mobile_0312.svg`,
    lv_show_banner: `${PUBLIC_URL}/images/LV_Show_banner_0229.svg`,
    lv_show__mobile: `${PUBLIC_URL}/images/LV_Show_banner_f_mobile_0401.svg`,
    lv_show_pc: `${PUBLIC_URL}/images/LV_Show_banner_f_web_0325.svg`,
    bos_event_1: `${PUBLIC_URL}/images/보스턴-날씨,이벤트.jpg`,
    bos_event_2: `${PUBLIC_URL}/images/보스턴-맛집 추천.jpg`,
    bos_event_3: `${PUBLIC_URL}/images/보스턴-가 볼만 한 곳.jpg`,
    bos_event_4: `${PUBLIC_URL}/images/보스턴-일정추천.jpg`,

    lv_event_1: `${PUBLIC_URL}/images/라스베가스-날씨,이벤트.jpg`,
    lv_event_2: `${PUBLIC_URL}/images/라스베가스-맛집 추천.jpg`,
    lv_event_3: `${PUBLIC_URL}/images/라스베가스-가 볼만 한 곳.jpg`,
    lv_event_4: `${PUBLIC_URL}/images/라스베가스-일정추천.jpg`,

    nf_event_1: `${PUBLIC_URL}/images/나이아가라-날씨,이벤트.jpg`,
    nf_event_2: `${PUBLIC_URL}/images/나이아가라-맛집.jpg`,
    nf_event_3: `${PUBLIC_URL}/images/나이아가라-가 볼만 한 곳.jpg`,
    nf_event_4: `${PUBLIC_URL}/images/나이아가라-일정추천.jpg`,

    las_event_banner: `${PUBLIC_URL}/images/Las_Event_banner_0404.svg`,
    las_event_banner_text_mobile: `${PUBLIC_URL}/images/Las_Event_banner_f_mobile_0404.svg`,
    las_event_banner_text_pc: `${PUBLIC_URL}/images/Las_Event_banner_f_pc_0404.svg`,

    bos_event_banner: `${PUBLIC_URL}/images/Bos_Event_banner_0404.svg`,
    bos_event_banner_text_mobile: `${PUBLIC_URL}/images/Bos_Event_banner_f_mobile_0404.svg`,
    bos_event_banner_text_pc: `${PUBLIC_URL}/images/Bos_Event_banner_f_pc_0404.svg`,

    nf_event_banner: `${PUBLIC_URL}/images/NF_Event_banner_0404.svg`,
    nf_event_banner_text_mobile: `${PUBLIC_URL}/images/NF_Event_banner_f_mobile_0404.svg`,
    nf_event_banner_text_pc: `${PUBLIC_URL}/images/NF_Event_banner_f_pc_0404.svg`,

    tamice_pop_up: `${PUBLIC_URL}/images/tamice_pop_up.png`,
    empty_chart_image: `${PUBLIC_URL}/images/빈 장바구니 이미지.png`,
    // empty_chart_text: `${PUBLIC_URL}/images/장바구니문구.svg`,

  },
};

export const API = process.env.REACT_APP_API;
export const API_OAUTH = process.env.REACT_APP_API_OAUTH;

export const delaySeconds = (milliseconds: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(1);
    }, milliseconds);
  });
};

export const fetcher =
  <T, U>(endpoint: string, map?: (data: T) => U) =>
  async () => {
    const res = await fetch(`${API}${endpoint}`);
    const data = await res.json();

    return map ? map(data) : data;
  };

export const newFetcher =
  <T, U>(endpoint: string, token: string, map?: (data: T) => U) =>
  async () => {
    const res = await fetch(`${API}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();

    return map ? map(data) : data;
  };

export const reFetcher =
  <T, U>(endpoint: string, token: string, map?: (data: T) => U) =>
  async () => {
    const res = await fetch(`${API}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await res.json();

    return map ? map(data) : data;
  };
export const poster =
  (endpoint: string, token: string, method: "POST" | "PUT" | "DELETE" = "POST") =>
  async (data: Object) => {
    return await fetch(`${API}${endpoint}`, {
      method: method,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "X-XSRF-TOKEN": `Bearer ${token}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.error) {
          throw new Error(JSON.stringify(data.error), data.error);
        }
        if (data?.errors?.length) {
          throw new Error(JSON.stringify(data.errors), data.errors);
        }
        return data;
      })
      .catch((err) => ({ err }));
  };

export const rePoster =
  (endpoint: string, token: string, method: "POST" | "PUT" | "DELETE" = "POST") =>
  async (data: Object) => {
    try {
      const response = await fetch(`${API}${endpoint}`, {
        method: method,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          "X-XSRF-TOKEN": `Bearer ${token}`,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();

      if (responseData?.error) {
        throw new Error(JSON.stringify(responseData.error), responseData.error);
      }

      if (responseData?.errors?.length) {
        throw new Error(JSON.stringify(responseData.errors), responseData.errors);
      }

      return responseData;
    } catch (err) {
      return { err };
    }
  };

export const posterWithoutToken =
  (endpoint: string, method: "POST" | "PUT" | "DELETE" = "POST") =>
  async (data: Object) => {
    return await fetch(`${API}${endpoint}`, {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.error) {
          throw new Error(JSON.stringify(data.error), data.error);
        }
        if (data?.errors?.length) {
          throw new Error(JSON.stringify(data.errors), data.errors);
        }
        return data;
      })
      .catch((err) => ({ err }));
  };

export const non_member_credentials =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImprQGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiMTIzNDU2In0.36SGml3IMTfvRV0iu1Tf9hWCA7AvGIgEekAHjs8OyJc";

import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  cityIdState,
  menuLoadingState,
  menuState,
  pageNumState,
} from "../../App";
import { staticFiles } from "../../shared";
import { ProductCard } from "../../shared/components/ProductCard";
import { useGetTickets } from "../../shared/hooks";
import { PackageBuyDetail } from "./components/PackageBuyDetail";

export const BigApplePassView = () => {
  // Define Global State variables
  const [menu] = menuState.useState();
  const [cityId] = cityIdState.useState();
  const [totalNum, setTotalNum] = pageNumState.useState();
  const [menuLoading, setMenuLoading] = menuLoadingState.useState();

  // Define component State variables
  const [displayFilter, setDisplayFilter] = useState(false);
  const [page, setPage] = useState(totalNum);
  const [isShowing, setIsShowing] = useState(true);
  const [tickets, setTickets] = useState<any>([]);
  const [isFirst, setIsFirst] = useState(true);

  // Create a navigate instance from useNavigate Hook
  const navigate = useNavigate();

  // Get Tickets from useGetTickets custom Hook with api call
  const { tickets: allTickets } = useGetTickets({
    category: Number(menu[1]?.dropdownElements?.[0].category_id),
    subCategoryId: Number(menu[1]?.dropdownElements?.[0].id),
    menu,
    menuLoading,
  });

  // Function to check auth Token expiration
  const checkTokenExpiration = () => {
    const token = localStorage.getItem("authToken");

    if (token) {
      const decodedToken: any = jwtDecode(token);

      const currentTime = Date.now() / 1000; // Convert current time to seconds

      if (decodedToken.exp < currentTime) {
        // Token has expired, force logout here
        // For example, clear the token from local storage and redirect the user to the login page
        localStorage.removeItem("authToken");
        localStorage.removeItem("loginData");
        localStorage.removeItem("order_number");
        localStorage.removeItem("useremail");

        navigate("/");
      }
    }
  };

  // Function to handle button click
  const handleClick = () => {
    if (tickets) {
      setPage(tickets.length); // Set the page number to the total number of tickets
      setIsShowing(false); // Hide the "Load More" button
    }
  };

  // **control number of tickets to display**
  // const handleClick = () => {
  //   if (!!tickets) {
  //     if (tickets.length <= page + 5) {
  //       setPage(tickets.length);
  //       setTotalNum(tickets.length);
  //       setIsShowing(false);
  //     } else {
  //       setPage(page + 5);
  //       setTotalNum(page + 5);
  //     }
  //   }
  // }

  // Define useEffect Hooks to handle several conditions
  useEffect(() => {
    // console.log("tickets are ", tickets);
    const intervalId = setInterval(checkTokenExpiration, 1000); // Check token expiration every second
    return () => clearInterval(intervalId); // Clear the interval when the component unmounts
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  useEffect(() => {
    setPage(totalNum || 5);
  }, [cityId]);

  useEffect(() => {
    setTickets(allTickets);
    return () => {
      setTickets([]);
    };
  }, [allTickets]);

  // Check if allTickets is defined and not empty before slicing
  // const tickets = allTickets?.slice(0, 10000) || [];

  const [bigApplePassImage, setBigApplePassImage] = useState(
    staticFiles.images.big_apple_pass_price
  );

  // Simplified useEffect to manage the image source based on cityId and screen size
  useEffect(() => {
    const updateImage = () => {
      let imageSrc;
      // Check both cityId and window width to determine the appropriate image source
      if (window.innerWidth < 768) {
        switch (cityId) {
          case 1:
            imageSrc = staticFiles.images.ny_big_apple_pass_price_mobile;
            break;
          case 36:
            imageSrc = staticFiles.images.SF_Price_Chart;
            break;
          case 56:
            imageSrc = staticFiles.images.bos_apple_pass_price; // Assuming you have a corresponding image for cityId 56
            break;
          case 57:
            imageSrc = staticFiles.images.nf_big_apple_pass_price; // Assuming you have a corresponding image for cityId 56
            break;
          case 58:
            imageSrc = staticFiles.images.ls_bigapple_pass_price; // Assuming you have a corresponding image for cityId 56
            break;
          // Removed the duplicate case for cityId 1 as it seemed to be a mistake
          default:
            imageSrc = staticFiles.images.big_apple_pass_price;
        }
        // Define the mobile image source here based on cityId or any other condition
      } else {
        // Original switch statement for desktop or wider screens
        switch (cityId) {
          case 1:
            imageSrc = staticFiles.images.big_apple_pass_price;
            break;
          case 36:
            imageSrc = staticFiles.images.SF_Price_Chart;
            break;
          case 56:
            imageSrc = staticFiles.images.bos_apple_pass_price; // Assuming you have a corresponding image for cityId 56
            break;
          case 57:
            imageSrc = staticFiles.images.nf_big_apple_pass_price; // Assuming you have a corresponding image for cityId 56
            break;
          case 58:
            imageSrc = staticFiles.images.ls_bigapple_pass_price; // Assuming you have a corresponding image for cityId 56
            break;
          // Removed the duplicate case for cityId 1 as it seemed to be a mistake
          default:
            imageSrc = staticFiles.images.big_apple_pass_price;
        }
      }
      setBigApplePassImage(imageSrc);
    };

    updateImage();
    // Listen for resize events to update the image source when the window size changes
    window.addEventListener("resize", updateImage);

    // Cleanup function to remove the event listener when the component unmounts
    return () => window.removeEventListener("resize", updateImage);
  }, [cityId]); // Depend on cityId to re-run the effect when it changes

  return (
    <>
      <div className="flex flex-col items-center justify-center mb-8">
        <img src={bigApplePassImage} width="100%" alt="big apple pass price" />
      </div>
      <div className="flex flex-col-reverse w-full gap-x-8 md:flex-row">
        <div className="flex flex-col w-full md:w-2/3 gap-y-8">
          {displayFilter ? (
            <PackageBuyDetail
              tour={true}
              handlePage={setPage}
              allTickets={tickets}
              tickets={tickets?.slice(0, page) || []}
            />
          ) : (
            tickets
              ?.slice(0, page)
              ?.map((item: any) => <ProductCard key={item.name} {...item} />)
          )}
          {isShowing ? (
            <div
              className="flex justify-center text-base text-blue hover:cursor-pointer hover:underline font-poppins"
              onClick={handleClick}
            >
              전체보기
            </div>
          ) : (
            <></>
          )}
          {/* <MainButton
          text={"티켓구입"}
          containerClassName="w-full block md:hidden"
          onClick={() => setDisplayFilter((prev) => !prev)}
        /> */}
        </div>
        <div className="w-full mt-8 md:w-1/3 md:block font-poppins md:mt-0">
          <PackageBuyDetail
            tour={true}
            handlePage={setPage}
            allTickets={tickets}
            tickets={tickets || []} // Use the updated tickets array
            categoryId={Number(menu[1]?.dropdownElements?.[0].category_id)}
            subCategoryId={Number(menu[1]?.dropdownElements?.[0].id)}
            subCategoryTitle={"SF Big Apple Pass"}
            subPath={"ba-pass"}
          />
        </div>
      </div>
    </>
  );
};
